import {AfterViewInit, Directive, EventEmitter, ViewContainerRef, Output} from '@angular/core'

@Directive({
  selector: '[isVisible]',
})

export class IsVisible implements AfterViewInit {
  @Output() insideViewport = new EventEmitter();

  constructor(private vcRef: ViewContainerRef) {
  }

  ngAfterViewInit() {
    console.log("IsVisible ngAfterViewInit" )
    const observedElement = this.vcRef.element.nativeElement.parentElement

    const observer = new IntersectionObserver((entries: any[]) => {
      entries.forEach((entry: any) => {
        if(!entry.isIntersecting) return;
        this.insideViewport.emit();
      })
    })
    observer.observe(observedElement)
  }
}
