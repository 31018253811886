import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { OwnUser } from '@mixidea-client/model';

import { environment } from './../../../../web/src/environments/environment';

import { take } from 'rxjs/operators';
import { LOG_KEY, LOG_LEVEL, LOG_TECH, LOG_MODULE } from '../interface/log';
import { LOG_TYPE } from '../interface/log';
import { is_ipad } from '@mixidea-client/util-func';
// import 'rxjs/add/operator/toPromise';
// import { toPromise } from 'rxjs/operators';

const logserver_url = environment.logserver_url;
// const logserver_url = "http://localhost:3000/client_log";
// const logserver_url = "https://recordingtranscription2.mixidea.org/client_log";

// const is_server_log = environment.is_server_log;

export interface LogParam {
  [LOG_KEY.event_id]?: string;
  [LOG_KEY.level]: LOG_LEVEL;
  [LOG_KEY.tech]?: LOG_TECH;
  [LOG_KEY.module]?: LOG_MODULE;
  [LOG_KEY.type]: LOG_TYPE;
  [LOG_KEY.message]: string;
  [LOG_KEY.file_name]: string;
  [LOG_KEY.trace]?: string;
  [LOG_KEY.teacher_id]?: string;
}

export interface LogParamSend extends LogParam {
  [LOG_KEY.user_id]?: string;
  [LOG_KEY.user_name]?: string;
  [LOG_KEY.client_time]?: string;
  [LOG_KEY.element]?: string;
  [LOG_KEY.send_type]?: string;
}

const server_log_level = { error: true, warn: true, info: true, debug: true, log: true };

// const server_log_level={error:false,warn:false,info:false,debug:false,log:false}
const console_log_level = { error: false, warn: false, info: false, debug: false, log: false };

const server_log_tech = { skyway: true, firebase: false, socket: true, transcript: false, recording: true, snapshot: true };
// const console_log_tech={skyway:true,firebase:true,socket:true,transcript:false,recording:true,snapshot:true};

const console_log_tech = {
  skyway: false,
  usermedia: false,
  firebase: false,
  socket: false,
  transcript: false,
  recording: false,
  snapshot: false,
  authenticate: false,
  network: false,
  speech_recognition: false,
  rxjs: false,
};

const server_log_type = {
  ComponentInit: false,
  RouterParamSubscript: true,
  ComponentChange: false,
  Subscription: false,
  ServiceConst: true,
  UserAction: true,
  Keypoint: true,
  LayoutInit: true,
  PageView: true,
  Capability: true,
  DataRetrieve: true,
};

const console_log_type = {
  ComponentInit: false,
  ComponentChange: false,
  ServiceConst: false,
  UserAction: false,
  Keypoint: false,
  LayoutInit: false,
};

/*sample code

import { LoggerService, LogParam, LOG_LEVEL, LOG_TECH, LOG_MODULE, LOG_TYPE  } from './../../../core/service/logger.service';

    const log_obj: LogParam = {message: 'user has logged off', level: LOG_LEVEL.ERROR, tech: LOG_TECH.skyway,
    type: LOG_TYPE.Keypoint, file_name: 'setting-env.component', module: LOG_MODULE.LiveVideoDebate};
    this.logger.write(log_obj);



private logger: LoggerService


 */

@Injectable()
export class LoggerService {
  own_user: OwnUser = { id: '', full_name: '' };
  is_ipad = false;

  constructor(private http: HttpClient) {
    this.is_ipad = is_ipad();
  }

  set_own_user(own_user: OwnUser) {
    this.own_user = own_user;
  }

  // write_local(log_obj: LogParam) {
  //   if (console_log_tech[log_obj.tech] !== false) {
  //     console.log(log_obj.file_name + ' : ' + log_obj.message);
  //   }
  // }

  write(log_obj: LogParamSend) {
    if (environment.is_server_log && server_log_type[log_obj.type] !== false) {
      // log_obj.client_time = String(Date.now());
      log_obj.user_name = this.own_user.full_name;
      log_obj.user_id = this.own_user.id;
      this.send_log(log_obj);
    }
    // if (console_log_tech[log_obj.tech] !== false && console_log_tech[log_obj.type] !== false) {
    //   console.log(log_obj.file_name + ' : ' + log_obj.message);
    // }
  }

  send_log(log_obj: LogParamSend) {
    // if (!is_server_log) {
    //   return;
    // }
    if (this.is_ipad) {
      log_obj.message = 'iPad: ' + log_obj.message;
    }

    let query_string = '?';
    for (const key in log_obj) {
      if (log_obj.hasOwnProperty(key)) {
        query_string = query_string + key + '=' + log_obj[key] + '&';
      }
    }

    const url = logserver_url + query_string;
    this.http
      .get(url)
      .pipe(take(1))
      .subscribe(
        (data) => {},
        (error) => {
          console.log('error for sending log', error);
          // if ('localStorage' in window && window.localStorage !== null) {
          //   const log_array_str: string = window.localStorage.getItem('log');
          //   if (!log_array_str) {
          //     return;
          //   }
          //   const log_array: [any] = JSON.parse(log_array_str) || [];
          //   if (log_array && !Array.isArray(log_array)) {
          //     return;
          //   }
          //   log_array.push(log_obj);
          //   const log_string = JSON.stringify(log_array);
          //   window.localStorage.setItem('log', log_string);
          // }
        },
      );
  }

  retry_sending_log() {
    // if (environment.is_server_log && 'localStorage' in window && window.localStorage !== null) {
    //   const log_array_str: string = window.localStorage.getItem('log');
    //   if (!log_array_str) {
    //     return;
    //   }
    //   const log_array: [LogParamSend] = JSON.parse(log_array_str) || [];
    //   if (!Array.isArray(log_array) || log_array.length < 1) {
    //     return;
    //   }
    //   window.localStorage.setItem('log', null);
    //   console.log('retry sending log');
    //   for (let i = 0; i < log_array.length; i++) {
    //     log_array[i].send_type = 'retry';
    //     this.send_log(log_array[i]);
    //   }
    // }
  }
}
