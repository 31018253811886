type userid = string;
import { ROLE_NAME } from '../constant/role';

export interface RoleUser {
  [ROLE_NAME.NA_PM]?: userid;
  [ROLE_NAME.NA_LO]?: userid;
  [ROLE_NAME.NA_MG]?: userid;
  [ROLE_NAME.NA_MO]?: userid;
  [ROLE_NAME.NA_PMR]?: userid;
  [ROLE_NAME.NA_LOR]?: userid;
  [ROLE_NAME.PDA_PM]?: userid;
  [ROLE_NAME.PDA_LO]?: userid;
  [ROLE_NAME.PDA_MG]?: userid;
  [ROLE_NAME.PDA_MO]?: userid;
  [ROLE_NAME.PDA_PMR]?: userid;
  [ROLE_NAME.PDA_LOR]?: userid;
  [ROLE_NAME.WSC_PM]?: userid;
  [ROLE_NAME.WSC_LO]?: userid;
  [ROLE_NAME.WSC_MG]?: userid;
  [ROLE_NAME.WSC_MO]?: userid;
  [ROLE_NAME.WSC_PMR]?: userid;
  [ROLE_NAME.WSC_LOR]?: userid;
  [ROLE_NAME.ASIAN_PM]?: userid;
  [ROLE_NAME.ASIAN_LO]?: userid;
  [ROLE_NAME.ASIAN_DPM]?: userid;
  [ROLE_NAME.ASIAN_DLO]?: userid;
  [ROLE_NAME.ASIAN_WG]?: userid;
  [ROLE_NAME.ASIAN_WO]?: userid;
  [ROLE_NAME.ASIAN_PMR]?: userid;
  [ROLE_NAME.ASIAN_LOR]?: userid;
  [ROLE_NAME.WSDC_PM]?: userid;
  [ROLE_NAME.WSDC_LO]?: userid;
  [ROLE_NAME.WSDC_DPM]?: userid;
  [ROLE_NAME.WSDC_DLO]?: userid;
  [ROLE_NAME.WSDC_WG]?: userid;
  [ROLE_NAME.WSDC_WO]?: userid;
  [ROLE_NAME.WSDC_PMR]?: userid;
  [ROLE_NAME.WSDC_LOR]?: userid;
  [ROLE_NAME.WSDC_SHORT_PM]?: userid;
  [ROLE_NAME.WSDC_SHORT_LO]?: userid;
  [ROLE_NAME.WSDC_SHORT_DPM]?: userid;
  [ROLE_NAME.WSDC_SHORT_DLO]?: userid;
  [ROLE_NAME.WSDC_SHORT_WG]?: userid;
  [ROLE_NAME.WSDC_SHORT_WO]?: userid;
  [ROLE_NAME.WSDC_SHORT_PMR]?: userid;
  [ROLE_NAME.WSDC_SHORT_LOR]?: userid;
  [ROLE_NAME.BP_PM]?: userid;
  [ROLE_NAME.BP_LO]?: userid;
  [ROLE_NAME.BP_DPM]?: userid;
  [ROLE_NAME.BP_DLO]?: userid;
  [ROLE_NAME.BP_MG]?: userid;
  [ROLE_NAME.BP_MO]?: userid;
  [ROLE_NAME.BP_GW]?: userid;
  [ROLE_NAME.BP_OW]?: userid;
  [ROLE_NAME.PHS_PM]?: userid;
  [ROLE_NAME.PHS_LO]?: userid;
  [ROLE_NAME.PHS_DPM]?: userid;
  [ROLE_NAME.PHS_DLO]?: userid;
  [ROLE_NAME.PHS_MG]?: userid;
  [ROLE_NAME.PHS_MO]?: userid;
  [ROLE_NAME.PHS_GW]?: userid;
  [ROLE_NAME.PHS_OW]?: userid;
}
