<section class="wrapper" style="margin: 24px">
  <header>
    <h2 class="heading-text">Login</h2>
    <button type="button" class="close" (click)="hide_login_modal()">
      <span class="fas fa-times" aria-label="close"></span>
    </button>
  </header>

  <ng-container *ngIf="redirectLogin; else popUpLogin">
    <div class="Spacer -h4"></div>
    <button class="SnsLoginBtn btn btn-block is-login-facebook" type="button" (click)="login_fb()">
      <i class="SnsLoginBtn__icon fab fa-facebook mr-2" aria-hidden="true"></i>
      Login with Facebook
    </button>

    <!-- <button class="SnsLoginBtn btn btn-block is-login-twitter" type="button" (click)="login_twitter()">
      <i class="SnsLoginBtn__icon fab fa-twitter-square mr-2" aria-hidden="true"></i>
      Login with Twitter
    </button> -->
    <div class="Spacer -h16"></div>

    <div class="try-another">
      <button class="email-login-btn" (click)="goto_mail_logion()">Email login</button>
    </div>
    <!-- <div class="term">
      <a target="_blank" class="btn btn-sm btn-link" [href]="term_of_service_url">term of services</a>
    </div> -->
  </ng-container>
  <ng-template #popUpLogin>
    login with popup

    <button class="SnsLoginBtn btn btn-block is-login-facebook" type="button" (click)="login_fb_with_popup()">
      <i class="SnsLoginBtn__icon fab fa-facebook mr-2" aria-hidden="true"></i>
      Popup Login with Facebook
    </button>
    <div class="Spacer -h16"></div>

    <button class="SnsLoginBtn btn btn-block is-login-twitter" type="button" (click)="login_twitter_with_popup()">
      <i class="SnsLoginBtn__icon fab fa-twitter-square mr-2" aria-hidden="true"></i>
      Popup Login with Twitter
    </button>

    <div class="alert-login">
      <div>Common Login Failure reason is</div>
      <div>"Third Party Cookie Block"</div>
      <div>Plase disable "Advertisement block"</div>
      <div>and try login again</div>
    </div>

    <div class="final"><a href="javascript:void(0)" (click)="goto_mail_logion()">final option if still fail</a></div>
  </ng-template>
</section>
