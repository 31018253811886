import firebase from 'firebase/compat/app';

export interface StudyContentCategory {
  id: string;
  title: string;
}

export interface StudyContentGroup {
  id: string;
  content_category_id: string;
  title: string;
  content_group_thumbnail: string;
  description: string;
}

export interface StudyContent {
  id?: string;
  content_group_id?: string;
  title?: string;
  sub_title?: string;
  content_type?: STUDY_CONTENT_TYPE;
  writing_subject?: string;
  writing_sample_answer?: string;
  spaeking_script?: string;
  reference_text?: string;
  speaking_subject?: string;
  video_url?: string;
  pdf_url?: string;
  pdf_page_num?: number;
  added_date?: Date;
  updated_date?: Date;
  added_timestamp?: firebase.firestore.Timestamp;
  updated_timestamp?: firebase.firestore.Timestamp;
}

export interface StudyDataAll {
  study_content_category_arr?: StudyContentCategory[];
  study_content_group_arr?: StudyContentGroup[];
  study_content_arr?: StudyContent[];
  study_content_updated_time?: string;
}

export enum WorkType {
  WorkTypeWriting = 'WorkTypeWriting',
  WorkTypeSpeaking = 'WorkTypeSpeaking',
}

export enum CORRECTION_STATUS {
  NOT_REQUESTED = 'NOT_REQUESTED',
  REQUESTED = 'REQUESTED',
  DONE = 'DONE',
}

export interface StudyWork {
  id?: string;
  associated_study_id?: string;
  user_id: string;
  work_type: WorkType;
  work_writing: string;
  writing_subject?: string;
  study_content_group_title?: string;
  study_content_title?: string;
  reference_text?: string;
  access_allowed_user: string[];
  assigned_corrector: string[] | firebase.firestore.FieldValue;
  correction_done_by: string[] | firebase.firestore.FieldValue;
  submit_date: Date;
  correction_requested: boolean;
  correction_done: boolean;
  correction_checked_by_student: boolean;
  correction_checked_completed_by_student: boolean;
  correction_opened_by_student: boolean;
  added_date?: Date;
  updated_date?: Date;
  correction_done_date?: Date;
  added_timestamp?: firebase.firestore.Timestamp;
  updated_timestamp?: firebase.firestore.Timestamp;
  correction_done_timestamp?: firebase.firestore.Timestamp;
  correction_status?: CORRECTION_STATUS; // this wont be stored in database but adjusted from correction_requested and correction_done
  is_model_correction?: boolean;
  model_correction_comment?: string;
}

export interface StudyCorrection {
  id?: string;
  associated_study_work_id?: string;
  work_type: WorkType;
  corrector_userid: string;
  correction_grammar: string;
  correction_grammar_markdown: string;
  correction_ideal_essay: string;
  correction_ideal_essay_markdown: string;
  correction_remember: string;
  correction_remember_markdown: string;
  correction_comment: string;
  correction_comment_markdown: string;

  added_date?: Date;
  updated_date?: Date;
  added_timestamp?: firebase.firestore.Timestamp;
  updated_timestamp?: firebase.firestore.Timestamp;
}

export enum STUDY_CONTENT_TYPE {
  VIDEO = 'VIDEO',
  PRESENTATION_ONLY = 'PRESENTATION_ONLY',
  EXERCISE_WRITING = 'EXERCISE_WRITING',
  EXERCISE_SAMPLE_WRIING = 'EXERCISE_SAMPLE_WRIING',
  EXERCISE_SPEAKING = 'EXERCISE_SPEAKING',
  EXERCISE_SAMPLE_SPEAKING = 'EXERCISE_SAMPLE_SPEAKING',
  REFERENCE = 'REFERENCE',
}
