import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

/*header component*/

import { HeaderComponent } from './header/header.component';

/* common component */

/* modal*/
/*
import { LoginModalComponent } from './login-modal/login-modal.component';
*/

/* additional sub layout */
// import {RightColumnAdComponent} from './right-column-ad/right-column-ad.component'

/*pipe */

import { KeysInObjectPipe } from './pipes/keys-in-object.pipe';

// import { UserLinkTestComponent } from './user-link-test/user-link-test.component';

/*
const firebaseConfig = {
    apiKey: "AIzaSyBp_ZDqoPygbPs7jMclrBSJ3a99t1Yvr1k",
    authDomain: "mixidea-91a20.firebaseapp.com",
    databaseURL: "https://mixidea-91a20.firebaseio.com",
    storageBucket: "mixidea-91a20.appspot.com",
    messagingSenderId: "46563705700"
  };

const firebaseAuthConfig = {
    provider: AuthProviders.Facebook,
    method: AuthMethods.Popup
  }
*/

import { LoginModalComponent } from './../shared/login-modal/login-modal.component';

import { UploadPresentationModalComponent } from '../shared/upload-presentation-modal/upload-presentation-modal.component';
// import { NotificationMessageModalComponent } from './notification-message-modal/notification-message-modal.component';

import { FacebookModule } from 'ngx-facebook';

import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextFieldModule } from '@angular/cdk/text-field';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

import { UserShowBaseComponent } from './usershow/user-show-base/user-show-base.component';
import { UserShowIconComponent } from './usershow/user-show-icon/user-show-icon.component';
import { UserShowNameComponent } from './usershow/user-show-name/user-show-name.component';
import { UserShowIconNameComponent } from './usershow/user-show-icon-name/user-show-icon-name.component';
import { EachBaseComponent } from './message/each-base/each-base.component';
// import { NotificationEachComponent } from './notification-chat/notification-each/notification-each.component';
// import { NotificationEachMessageComponent } from './notification-chat/notification-each-message/notification-each-message.component';
// import { NotificationEachOgpComponent } from './notification-chat/notification-each-ogp/notification-each-ogp.component';
// import { NotificationEachAttachmentComponent } from './notification-chat/notification-each-attachment/notification-each-attachment.component';
import { LinklifiedHtmlComponent } from './message/linklified-html/linklified-html.component';
import { ShowTimePipe } from './pipes/show-time.pipe';
import { ShowMinutesHourPipe } from './pipes/show-minutes-hour.pipe';
import { RoleLongshownamePipe } from './pipes/role-longshowname.pipe';
import { RoleSidePipe } from './pipes/role-side.pipe';
import { ShowDayHourPipe } from './pipes/show-day-hour.pipe';
import { UserShowOwnIconComponent } from './usershow/user-show-own-icon/user-show-own-icon.component';
import { ManualModalComponent } from './manual-modal/manual-modal.component';
import { BtnCircleComponent } from './btn-circle/btn-circle.component';
import { SetUsernameModalComponent } from './set-username-modal/set-username-modal.component';
import { FooterComponent } from './footer/footer.component';
import { LineLoginLinkComponent } from './line-login-link/line-login-link.component';

import { AdAreaComponent } from './ad-area/ad-area.component';
import { LinkToStudyServiceComponent } from './link-to-study-service/link-to-study-service.component';

import { IsVisible } from './directives/is-visible.directive';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    /*AngularFireModule.initializeApp(firebaseConfig, firebaseAuthConfig),*/
    RouterModule,
    FacebookModule.forRoot(),
    MatDialogModule,
    MatInputModule,
    MatProgressBarModule,
    MatTooltipModule,
    LayoutModule,
    OverlayModule,
    PortalModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    DragDropModule,
    TextFieldModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  declarations: [
    // RightColumnAdComponent,
    KeysInObjectPipe,
    HeaderComponent,
    // UserLinkTestComponent,
    // NotificationMessageModalComponent,
    LoginModalComponent,
    UserShowBaseComponent,
    UserShowIconComponent,
    UserShowNameComponent,
    UserShowIconNameComponent,
    EachBaseComponent,
    // NotificationEachComponent,
    // NotificationEachMessageComponent,
    // NotificationEachOgpComponent,
    // NotificationEachAttachmentComponent,
    LinklifiedHtmlComponent,
    ShowTimePipe,
    RoleLongshownamePipe,
    RoleSidePipe,
    ShowMinutesHourPipe,
    ShowDayHourPipe,
    IsVisible,
    UserShowOwnIconComponent,
    ManualModalComponent,
    BtnCircleComponent,
    SetUsernameModalComponent,
    FooterComponent,
    UploadPresentationModalComponent,
    LineLoginLinkComponent,
    AdAreaComponent,
    LinkToStudyServiceComponent,
  ],
  entryComponents: [
    LoginModalComponent,
    ManualModalComponent,
    SetUsernameModalComponent,
    UploadPresentationModalComponent,
    LineLoginLinkComponent,
  ],
  exports: [
    // RightColumnAdComponent,
    KeysInObjectPipe,
    HeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    // ModalModule,
    FacebookModule,
    LoginModalComponent,
    MatDialogModule,
    MatInputModule,
    MatProgressBarModule,
    MatTooltipModule,
    LayoutModule,
    OverlayModule,
    PortalModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    DragDropModule,
    TextFieldModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    UserShowIconComponent,
    UserShowNameComponent,
    UserShowIconNameComponent,
    LinklifiedHtmlComponent,
    ShowTimePipe,
    RoleLongshownamePipe,
    RoleSidePipe,
    ShowMinutesHourPipe,
    ShowDayHourPipe,
    IsVisible,
    UserShowOwnIconComponent,
    ManualModalComponent,
    BtnCircleComponent,
    SetUsernameModalComponent,
    FooterComponent,
    UploadPresentationModalComponent,
    LineLoginLinkComponent,
    // AdAreaComponent,
    LinkToStudyServiceComponent,
  ],
})
export class SharedModule {}
