import { FormControl } from '@angular/forms';

import { WebrtcSupportedEnvCheck, DEFAULT_WEBRTC_SUPPORTED_ENVCHECK, DEVICE_SUPPORT_TYPE, IosInfo } from '@mixidea-client/model';

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export const generate_id_short = function () {
  return s4() + s4();
};

export const generate_id = function () {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export const generate_id2 = function () {
  return s4() + s4() + '_' + s4() + '_' + s4() + '_' + s4() + '_' + s4() + s4() + s4();
};

export const generate_random_string = function () {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

export const create_randomstring = function (n) {
  const CODE_TABLE = '0123456789abcdefghijklmnopqrstuvwxyz';
  let r = '';
  for (let i = 0, k = CODE_TABLE.length; i < n; i++) {
    r += CODE_TABLE.charAt(Math.floor(k * Math.random()));
  }
  return r;
};

export function validateTrue(ctrl: FormControl) {
  const value = ctrl.value;
  if (value === true) {
    return null;
  } else {
    return { forbiddenValue: value };
  }
}

export function get_minutes_second_string(total_second) {
  const seconds = total_second % 60;
  const minutes = (total_second - seconds) / 60;
  let time_string = String(minutes) + ':' + String(seconds);

  if (seconds < 10) {
    time_string = String(minutes) + ':0' + String(seconds);
  } else {
    time_string = String(minutes) + ':' + String(seconds);
  }
  return time_string;
}

type DeviceType = 'sp' | 'pc' | 'tab';

export function detect_device(): DeviceType {
  const ua = navigator.userAgent;
  if (
    ua.indexOf('iPhone') > 0 ||
    ua.indexOf('iPod') > 0 ||
    (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) ||
    ua.indexOf('Windows Phone') > 0
  ) {
    return 'sp';
  } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
    return 'tab';
  } else {
    return 'pc'; // it might be others
  }
}

export function is_mobile(): boolean {
  const device: DeviceType = detect_device();
  return device === 'sp';
}

export function is_tablet(): boolean {
  const device: DeviceType = detect_device();
  return device === 'tab';
}

export function is_mac_ipad(): boolean {
  const isIPad = /iPad|Macintosh/i.test(navigator.userAgent) && 'ontouchend' in document;
  return isIPad;
}

export function is_safari(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1) {
    return true;
  }
  return false;
}

export function is_firefox(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('firefox') !== -1) {
    return true;
  }
  return false;
}

export function is_android() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('android') !== -1) {
    return true;
  }
  return false;
}

export function is_ios() {
  const userAgent = navigator.userAgent.toLowerCase();
  const is_iphone = userAgent.indexOf('iphone') > -1;
  const is_pad = userAgent.indexOf('ipad') > -1;
  const is_ios = is_iphone || is_pad;
  return is_ios ? true : false;
}

export function retrieve_safari_version() {
  let safari_version = (/Version\S[0-9][0-9].[0-9]/g.exec(navigator.userAgent) || [])[0];
  let safari_version_detail = (/Version\S[0-9][0-9].[0-9].[0-9]/g.exec(navigator.userAgent) || [])[0];

  if (safari_version && safari_version_detail) {
    const detail_version = safari_version_detail.substr(-1);
    safari_version = safari_version + detail_version; //user version 13.11 instead of 13.1.1 to compare the value
  }
  if (!safari_version) {
    return 0;
  } else {
    const version = safari_version.replace(`Version/`, ``);
    const version_num = parseFloat(version);
    if (version_num) {
      return version_num;
    } else {
      return 0;
    }
  }
}

export function detect_mobile_or_tablet() {
  const ua = navigator.userAgent;
  if (ua.indexOf('iPhone') > 0) {
    return 'iPhone';
  }
  if (ua.indexOf('iPod') > 0) {
    return 'iPod';
  }
  if (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
    return 'android_mobile';
  }
  if (ua.indexOf('Windows Phone') > 0) {
    return 'windows_mobile';
  }
  if (ua.indexOf('iPad') > 0) {
    return 'iPad';
  }
  if (ua.indexOf('Android') > 0) {
    return 'android_tablet';
  }
  return null;
}

export function retrieve_iOS_version_string() {
  let iOS_version = ('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
    .replace('undefined', '3_2')
    .replace('_', '.')
    .replace('_', '');
  return iOS_version;
}

export function retrieve_iOS_version() {
  let iOS_version = retrieve_iOS_version_string();

  if (!iOS_version) {
    return null;
  } else {
    return parseFloat(iOS_version);
  }
}

export function check_webrtc_supported_env(): WebrtcSupportedEnvCheck {
  const ua = navigator.userAgent.toLowerCase();
  const ver = navigator.appVersion.toLowerCase();
  return check_webrtc_supported_env_execute(ua, ver);
}

function check_webrtc_supported_env_execute(userAgent, appVersion): WebrtcSupportedEnvCheck {
  const supported_env: WebrtcSupportedEnvCheck = DEFAULT_WEBRTC_SUPPORTED_ENVCHECK;

  // iphone or ipad
  const is_iphone = userAgent.indexOf('iphone') > -1;
  const is_pad = userAgent.indexOf('ipad') > -1;
  const is_ios = is_iphone || is_pad;
  const is_android = userAgent.indexOf('android') > -1;

  if (is_ios) {
    supported_env.is_ios = true;
    supported_env.ios_version = retrieve_iOS_version();

    if (supported_env.ios_version < 12.2) {
      supported_env.is_ios_oldversion = true;
      supported_env.open_alert_dialog_immediately = true;
      supported_env.is_ios_oldversion_src = 'https://storage.googleapis.com/mixidea_resource/ios.png';
      supported_env.is_supported = false;
      supported_env.device_support_type = DEVICE_SUPPORT_TYPE.IOS_OLD_VERSION;
      return supported_env;
      // return false;
    } else {
      // ios larger than 11
      const is_safari = userAgent.indexOf('safari') > -1;
      const is_ios_chrome = userAgent.indexOf('crios') > -1;
      const is_ios_facebook_app = userAgent.indexOf('fbios') > -1;
      const is_ios_line_app = userAgent.indexOf('line') > -1;
      const is_ios_messenger_app = userAgent.indexOf('messengerforios') > -1;
      const is_ios_safari_view_app = !navigator.mediaDevices;
      if (is_ios_chrome) {
        // iOS Chrome
        supported_env.is_ios_supported_browser = false;
        supported_env.unsupported_browser_name = 'Chrome';
        supported_env.open_alert_dialog_immediately = false;
        supported_env.unsupported_browser_image_src = 'https://storage.googleapis.com/mixidea_resource/Chrome.png';
        supported_env.ios_open_safari_image_src = 'https://storage.googleapis.com/mixidea_resource/Safari.png';
        supported_env.is_supported = false;
        supported_env.device_support_type = DEVICE_SUPPORT_TYPE.IOS_WEBVIEW;
        return supported_env;
      } else if (is_ios_facebook_app) {
        // iOS Facebook App
        supported_env.is_ios_supported_browser = false;
        supported_env.unsupported_browser_name = 'FacebookAppBrowser';
        supported_env.unsupported_browser_image_src = 'https://storage.googleapis.com/mixidea_resource/facebookbrowser.png';
        supported_env.open_alert_dialog_immediately = true;
        supported_env.ios_open_safari_image_src = 'https://storage.googleapis.com/mixidea_resource/webview_ios_facebook2.jpg';
        supported_env.is_supported = false;
        supported_env.device_support_type = DEVICE_SUPPORT_TYPE.IOS_WEBVIEW;
        return supported_env;
      } else if (is_ios_messenger_app && is_iphone) {
        // iPhone Messenger App
        supported_env.is_ios_supported_browser = false;
        supported_env.unsupported_browser_name = 'FacebookMessengerBrowser';
        supported_env.unsupported_browser_image_src = 'https://storage.googleapis.com/mixidea_resource/Messenger.png';
        supported_env.open_alert_dialog_immediately = true;
        supported_env.ios_open_safari_image_src = 'https://storage.googleapis.com/mixidea_resource/webview_iphone_messenger2.jpg';
        supported_env.is_supported = false;
        supported_env.device_support_type = DEVICE_SUPPORT_TYPE.IOS_WEBVIEW;
        return supported_env;
      } else if (is_ios_messenger_app && is_pad) {
        // iPad Messenger App
        supported_env.is_ios_supported_browser = false;
        supported_env.unsupported_browser_name = 'FacebookMessengerBrowser';
        supported_env.unsupported_browser_image_src = 'https://storage.googleapis.com/mixidea_resource/Messenger.png';
        supported_env.open_alert_dialog_immediately = true;
        supported_env.ios_open_safari_image_src = 'https://storage.googleapis.com/mixidea_resource/webview_ipad_messenger.jpg';
        supported_env.is_supported = false;
        supported_env.device_support_type = DEVICE_SUPPORT_TYPE.IOS_WEBVIEW;
        return supported_env;
      } else if (is_ios_line_app) {
        // iOS Line App
        supported_env.is_ios_supported_browser = false;
        supported_env.unsupported_browser_name = 'LineBrowser';
        supported_env.unsupported_browser_image_src = 'https://storage.googleapis.com/mixidea_resource/Line.png';
        supported_env.open_alert_dialog_immediately = true;
        supported_env.ios_open_safari_image_src = 'https://storage.googleapis.com/mixidea_resource/webview_ios_line2.jpg';
        supported_env.is_supported = false;
        supported_env.device_support_type = DEVICE_SUPPORT_TYPE.IOS_WEBVIEW;
        return supported_env;
      } else if (is_ios_safari_view_app && is_pad) {
        // iPad Safari View App (e.g. Twitter)
        supported_env.is_ios_supported_browser = false;
        supported_env.open_alert_dialog_immediately = true;
        supported_env.ios_open_safari_image_src = 'https://storage.googleapis.com/mixidea_resource/ios_safari_view_ipad3.jpg';
        supported_env.is_supported = false;
        supported_env.device_support_type = DEVICE_SUPPORT_TYPE.IOS_SAFARIVIEW;
        return supported_env;
      } else if (is_ios_safari_view_app && is_iphone) {
        // iPhone Safari View App (e.g. Twitter)
        supported_env.is_ios_supported_browser = false;
        supported_env.open_alert_dialog_immediately = true;
        supported_env.ios_open_safari_image_src = 'https://storage.googleapis.com/mixidea_resource/ios_safari_view_iphone3.jpg';
        supported_env.is_supported = false;
        supported_env.device_support_type = DEVICE_SUPPORT_TYPE.IOS_SAFARIVIEW;
        return supported_env;
      } else if (is_safari) {
        // iOS Safari
        supported_env.is_ios_supported_browser = true; // this works
        supported_env.is_supported = true;
        supported_env.device_support_type = DEVICE_SUPPORT_TYPE.IOS_SAFARI;
        return supported_env;
      } else {
        // webview which useragent cannot be estimated, but this is
        supported_env.is_ios_supported_browser = false;
        supported_env.ios_open_safari_image_src = 'https://storage.googleapis.com/mixidea_resource/Safari.png';
        supported_env.is_supported = false;
        supported_env.device_support_type = DEVICE_SUPPORT_TYPE.IOS_UNKNOWN_APP;
        return supported_env;
      }
    }
  } else if (is_android) {
    supported_env.is_android = true;

    const is_android_facebook_app = userAgent.indexOf('fban') > -1 || userAgent.indexOf('fbav') > -1;
    const is_android_line_app = userAgent.indexOf('line') > -1;
    const isChrome = userAgent.indexOf('chrome') > -1 && userAgent.indexOf('edge') === -1;

    if (is_android_facebook_app) {
      // android facebook
      supported_env.is_unsupported_browser = true;
      supported_env.unsupported_browser_name = 'FacebookAppBrowser';
      supported_env.unsupported_browser_image_src = 'https://storage.googleapis.com/mixidea_resource/facebookbrowser.png';
      supported_env.open_alert_dialog_immediately = true;
      supported_env.android_open_chrome_src = 'https://storage.googleapis.com/mixidea_resource/android-facebook-chrome-open_5.png';

      supported_env.is_supported = false;
      supported_env.device_support_type = DEVICE_SUPPORT_TYPE.ANDROID_WEBVIEW;
      return supported_env;
    } else if (is_android_line_app) {
      // android Line
      supported_env.is_unsupported_browser = true;
      supported_env.unsupported_browser_name = 'LineBrowser';
      supported_env.unsupported_browser_image_src = 'https://storage.googleapis.com/mixidea_resource/Line.png';
      supported_env.open_alert_dialog_immediately = true;
      supported_env.android_open_chrome_src = 'https://storage.googleapis.com/mixidea_resource/android-line-chrome-open_4.png';

      supported_env.is_supported = false;
      supported_env.device_support_type = DEVICE_SUPPORT_TYPE.ANDROID_WEBVIEW;
      return supported_env;
    } else if (isChrome) {
      supported_env.is_supported = true;
      supported_env.device_support_type = DEVICE_SUPPORT_TYPE.ANDROID_CHROME;
      return supported_env;
    } else {
      supported_env.is_supported = true; // android device basically support if it is webview
      supported_env.device_support_type = DEVICE_SUPPORT_TYPE.ANDROID_UNKNOWN;
      return supported_env;
    }
  } else {
    // pc

    // IE(11以外)
    const isMSIE = userAgent.indexOf('msie') > -1 && userAgent.indexOf('opera') === -1;
    const isIE11 = userAgent.indexOf('trident/7') > -1;
    // IE
    const isIE = isMSIE || isIE11;
    // Edge
    const isEdge = userAgent.indexOf('edge') > -1;
    // macOS Safari
    const isSafari = userAgent.indexOf('safari') > -1 && userAgent.indexOf('chrome') === -1;
    // Opera
    const isOpera = userAgent.indexOf('opera') > -1;
    // Google Chrome except in ios
    const isChrome = userAgent.indexOf('chrome') > -1 && userAgent.indexOf('edge') === -1;
    // Firefox
    const isFirefox = userAgent.indexOf('firefox') > -1;

    if (isIE) {
      supported_env.is_unsupported_browser = true;
      supported_env.unsupported_browser_name = 'Internet Explorer';
      supported_env.unsupported_browser_image_src = 'https://storage.googleapis.com/mixidea_resource/IntenetExplorer.png';
      supported_env.is_supported = false;
      supported_env.device_support_type = DEVICE_SUPPORT_TYPE.PC_UNSUPPORTED_BROWSERS;
      return supported_env;
    } else if (isEdge) {
      supported_env.is_unsupported_browser = true;
      supported_env.unsupported_browser_name = 'Edge';
      supported_env.unsupported_browser_image_src = 'https://storage.googleapis.com/mixidea_resource/Edge.png';
      supported_env.is_supported = false;
      supported_env.device_support_type = DEVICE_SUPPORT_TYPE.PC_UNSUPPORTED_BROWSERS;
      return supported_env;
    } else if (isSafari) {
      const safari_version = retrieve_safari_version();
      if (safari_version < 12.1) {
        supported_env.is_supported = false;
        supported_env.is_safari_oldversion = true;
        supported_env.safari_version = safari_version;
        supported_env.is_safari_oldversion_src = 'https://storage.googleapis.com/mixidea_resource/Safari.png';
        supported_env.device_support_type = DEVICE_SUPPORT_TYPE.MAC_OLD_SAFARI;
        return supported_env;
      } else {
        supported_env.device_support_type = DEVICE_SUPPORT_TYPE.PC_SUPPORTED_BROWSERS;
        supported_env.is_supported = true;
        return supported_env;
      }
    } else if (isOpera) {
      supported_env.is_unsupported_browser = true;
      supported_env.unsupported_browser_name = 'Opera';
      supported_env.unsupported_browser_image_src = 'https://storage.googleapis.com/mixidea_resource/Opera.png';

      supported_env.is_supported = false;
      supported_env.device_support_type = DEVICE_SUPPORT_TYPE.PC_UNSUPPORTED_BROWSERS;
      return supported_env;
    } else if (isChrome) {
      supported_env.is_supported = true;
      supported_env.device_support_type = DEVICE_SUPPORT_TYPE.PC_SUPPORTED_BROWSERS;
      return supported_env;
    } else if (isFirefox) {
      supported_env.is_unsupported_browser = true;
      // supported_env.unsupported_browser_name = 'Firefox';
      // supported_env.unsupported_browser_image_src = 'https://storage.googleapis.com/mixidea_resource/Firefox.png';
      supported_env.is_supported = true;
      supported_env.device_support_type = DEVICE_SUPPORT_TYPE.PC_SUPPORTED_BROWSERS;
      return supported_env;
    } else {
      supported_env.is_supported = false;
      supported_env.device_support_type = DEVICE_SUPPORT_TYPE.PC_UNSUPPORTED_BROWSERS;
      return supported_env;
    }
  }
}

export function is_ipad() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('ipad') > -1 || (ua.indexOf('macintosh') > -1 && 'ontouchend' in document)) {
    return true;
  }
  return false;
}

export function check_ios_version_for_video(): IosInfo {
  const ua = navigator.userAgent.toLowerCase();
  const ver = navigator.appVersion.toLowerCase();

  const is_iphone = ua.indexOf('iphone') > -1;
  const is_pad = ua.indexOf('ipad') > -1;

  if (is_iphone || is_pad) {
    const ios_version = retrieve_iOS_version();

    if (ios_version < 13.6) {
      return {
        ios_img_src: 'https://storage.googleapis.com/mixidea_resource/ios.png',
        current_ios_version: ios_version,
      };
    }
  }
  return null;
}

export function is_old_ipados_for_video(): boolean {
  const mac_ipad = is_mac_ipad();
  if (mac_ipad) {
    const safari_version = retrieve_safari_version();
    if (safari_version < 13.12) {
      return true;
    }
  }
  return false;
}
